<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      table-identifier="report-order-table"
      sort-columns-enabled
      do-route
      do-route-filters
      no-meta-check
      show-filter
      headers-filterable
      prune-search-filters
      sortable
      sort-type="remote"
      resource="ordersV2"
      route-base="/orders/reports_orders"
      :headers="headers"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :show-operations="false"
      :search-filters="filtersList"
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import orderSetup from '../constants/order'
import statusText from '../components/status-text'
import originText from '../components/origin-text'

export default {
  name: 'ReportsOrdersList',
  metaInfo() {
    return {
      title: this.$t('pages.orders.title')
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    return {
      recurringTypes: orderConst.recurringTypes,
      paymentMethods: orderConst.paymentMethods,
      brands: orderConst.paymentMethodBrands,
      states: orderConst.transactionStates,
      origins: orderConst.origins,
      basketItemStatuses: orderConst.basketItemStatuses,
      disputeReasons: orderConst.disputeReasons,
      disputeTypes: orderConst.disputeTypes,
      disputeStatuses: orderConst.disputeStatuses
    }
  },
  data() {
    return {
      headersDefaultHide: [
        'numberTxn',
        'email',
        'customerId',
        'usage',
        'receiptNumber',
        'staff',
        'registerId',
        'entity',
        'recurring',
        'paymentId',
        'cardNumber',
        'accountNumber',
        'bankName',
        'bic',
        'insuranceName',
        'insuranceId',
        'cashierNumber',
        'balanceNumber',
        'basketId',
        'terminalId',
        'cutoverId',
        'cutoverDate'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    headersConfig() {
      return safeGet(
        this.localConfiguration,
        'settings.headerFilters.orders',
        {}
      )
    },
    headers() {
      return [
        {
          field: 'updatedAt',
          label: this.$t('pages.orders.all.headers.last_updated'),
          minWidth: 140,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            return row.updatedAt
              ? this.$date.formatDateTime(row.updatedAt)
              : '-'
          }
        },
        {
          field: 'orderId',
          label: this.$t('pages.orders.all.headers.order_id'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'origin',
          label: this.$t('pages.orders.all.headers.origin'),
          fallback: '-',
          minWidth: 120,
          customRowComponent: originText,
          truncate: true
        },
        {
          field: 'totalAmount',
          label: this.$t('pages.orders.all.headers.total_amount'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'currency',
          label: this.$t('pages.orders.all.headers.currency'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'paymentMethods',
          label: this.$t('pages.orders.all.headers.payment_methods'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            const paymentMethods = []
            row?.payments?.forEach((payment) => {
              const brand =
                this.brands.find((brand) => brand.value === payment.brand)
                  ?.label || payment.brand
              paymentMethods.push(brand)
            })
            return paymentMethods.length ? paymentMethods.join(', ') : null
          }
        },
        {
          field: 'status',
          label: this.$t('pages.orders.all.headers.status'),
          fallback: '-',
          minWidth: 120,
          customRowComponent: statusText,
          truncate: true
        },
        {
          field: 'customer.displayName',
          label: this.$t('pages.orders.all.headers.customer_name'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.customer && row.customer.displayName
        },
        {
          field: 'salesChannel',
          label: this.$t('pages.orders.all.headers.sales_channel'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: 'payments.location',
          label: this.$t('pages.orders.all.headers.payment_origin'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            const paymentLocations = []
            row?.payments?.forEach((payment) => {
              paymentLocations.push(payment.location)
            })
            return paymentLocations.length ? paymentLocations.join(', ') : null
          }
        },
        {
          field: 'numberTxn',
          label: this.$t('pages.orders.all.headers.number_txn'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: 'customer.email',
          label: this.$t('pages.orders.all.headers.email'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.customer && row.customer.email
        },
        {
          field: 'customer.customerId',
          label: this.$t('pages.orders.all.headers.customer_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.customer && row.customer.customerId
        },
        {
          field: 'usage',
          label: this.$t('pages.orders.all.headers.usage'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'receiptNumber',
          label: this.$t('pages.orders.all.headers.receipt_number'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'staff',
          label: this.$t('pages.orders.all.headers.staff'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'registerId',
          label: this.$t('pages.orders.all.headers.register_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'entity',
          label: this.$t('pages.orders.all.headers.entity'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'recurring',
          label: this.$t('pages.orders.all.headers.recurring'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'paymentId',
          label: this.$t('pages.orders.all.headers.payment_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'cardNumber',
          label: this.$t('pages.orders.all.headers.card_number'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: 'accountNumber',
          label: this.$t('pages.orders.all.headers.account_number'),
          fallback: '-',
          minWidth: 160,
          truncate: true
        },
        {
          field: 'bankName',
          label: this.$t('pages.orders.all.headers.bank_name'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'bic',
          label: this.$t('pages.orders.all.headers.bic'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'insuranceName',
          label: this.$t('pages.orders.all.headers.insurance_provider'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'insuranceId',
          label: this.$t('pages.orders.all.headers.insurance_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'cashierNumber',
          label: this.$t('pages.orders.all.headers.cashier_number'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'balanceNumber',
          label: this.$t('pages.orders.all.headers.balance_number'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'basketId',
          label: this.$t('pages.orders.all.headers.basket_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'terminalId',
          label: this.$t('pages.orders.all.headers.terminal_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'cutoverId',
          label: this.$t('pages.orders.all.headers.cutover_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'cutoverDate',
          label: this.$t('pages.orders.all.headers.cutover_date'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'order_id',
          label: this.$t('pages.orders.all.filters.order_id.label'),
          type: 'input'
        },
        // More or less missing implementation
        {
          name: 'totalAmount',
          label: this.$t('pages.orders.all.filters.total_amount.label'),
          type: 'input'
        },
        ...(this.currencies.length > 1
          ? [
              {
                name: 'currency',
                type: 'currency-select',
                label: this.$t('pages.orders.all.filters.currency.label'),
                value: this.currentCurrency,
                options: this.currencies
              }
            ]
          : []),
        {
          name: 'payment_method',
          label: this.$t('pages.orders.all.filters.payment_method.label'),
          type: 'select',
          options: this.paymentMethods
        },
        {
          name: 'brand',
          label: this.$t('pages.orders.all.filters.brand.label'),
          type: 'select',
          options: this.brands
        },
        {
          name: 'status',
          label: this.$t('pages.orders.all.filters.status.label'),
          type: 'select',
          options: this.statuses
        },
        {
          name: 'number_txn',
          label: this.$t('pages.orders.all.filters.number_txn.label'),
          type: 'input'
        },
        {
          name: 'customer_name',
          label: this.$t('pages.orders.all.filters.customer_name.label'),
          type: 'input'
        },
        {
          name: 'email',
          label: this.$t('pages.orders.all.filters.email.label'),
          type: 'input'
        },
        {
          name: 'customer_id',
          label: this.$t('pages.orders.all.filters.customer_id.label'),
          type: 'input'
        },
        {
          name: 'customer_birthdate',
          label: this.$t('pages.orders.all.filters.customer_birthdate.label'),
          type: 'daterange',
          prop: ['start', 'end'],
          noFutureDates: true,
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'usage',
          label: this.$t('pages.orders.all.filters.usage.label'),
          type: 'input'
        },
        {
          name: 'receipt_number',
          label: this.$t('pages.orders.all.filters.receipt_number.label'),
          type: 'input'
        },
        {
          name: 'staff',
          label: this.$t('pages.orders.all.filters.staff.label'),
          type: 'input'
        },
        {
          name: 'register_id',
          label: this.$t('pages.orders.all.filters.register_id.label'),
          type: 'input'
        },
        {
          name: 'origin',
          label: this.$t('pages.orders.all.filters.origin.label'),
          type: 'select',
          options: this.origins
        },
        {
          name: 'recurring',
          label: this.$t('pages.orders.all.filters.recurring.label'),
          type: 'select',
          options: this.recurringTypes
        },
        {
          name: 'payment_id',
          label: this.$t('pages.orders.all.filters.payment_id.label'),
          type: 'input'
        },
        {
          name: 'card_number',
          label: this.$t('pages.orders.all.filters.card_number.label'),
          type: 'input'
        },
        {
          name: 'account_number',
          label: this.$t('pages.orders.all.filters.account_number.label'),
          type: 'input'
        },
        {
          name: 'bank_name',
          label: this.$t('pages.orders.all.filters.bank_name.label'),
          type: 'input'
        },
        {
          name: 'bic',
          label: this.$t('pages.orders.all.filters.bic.label'),
          type: 'input'
        },
        {
          name: 'insurance_provider',
          label: this.$t('pages.orders.all.filters.insurance_provider.label'),
          type: 'input'
        },
        {
          name: 'insurance_id',
          label: this.$t('pages.orders.all.filters.insurance_id.label'),
          type: 'input'
        },
        {
          name: 'cashier_number',
          label: this.$t('pages.orders.all.filters.cashier_number.label'),
          type: 'input'
        },
        {
          name: 'balance_number',
          label: this.$t('pages.orders.all.filters.balance_number.label'),
          type: 'input'
        },
        {
          name: 'basket_id',
          label: this.$t('pages.orders.all.filters.basket_id.label'),
          type: 'input'
        },
        {
          name: 'basket_item_id',
          label: this.$t('pages.orders.all.filters.basket_item_id.label'),
          type: 'input'
        },
        {
          name: 'basket_item_status',
          label: this.$t('pages.orders.all.filters.basket_item_status.label'),
          type: 'select',
          options: this.basketItemStatuses
        },
        {
          name: 'basket_item_name',
          label: this.$t('pages.orders.all.filters.basket_item_name.label'),
          type: 'input'
        },
        {
          name: 'basket_item_type',
          label: this.$t('pages.orders.all.filters.basket_item_type.label'),
          type: 'input'
        },
        {
          name: 'transaction_short_id',
          label: this.$t('pages.orders.all.filters.transaction_short_id.label'),
          type: 'input'
        },
        {
          name: 'transaction_unique_id',
          label: this.$t(
            'pages.orders.all.filters.transaction_unique_id.label'
          ),
          type: 'input'
        },
        {
          name: 'terminal_id',
          label: this.$t('pages.orders.all.filters.terminal_id.label'),
          type: 'input'
        },
        {
          name: 'cutover_id',
          label: this.$t('pages.orders.all.filters.cutover_id.label'),
          type: 'input'
        },
        {
          name: 'cutover_date',
          label: this.$t('pages.orders.all.filters.cutover_date.label'),
          type: 'daterange',
          prop: ['start', 'end'],
          noFutureDates: true,
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'dispute_id',
          label: this.$t('pages.orders.all.filters.dispute_id.label'),
          type: 'input'
        },
        {
          name: 'dispute_reason',
          label: this.$t('pages.orders.all.filters.dispute_reason.label'),
          type: 'select',
          options: this.disputeReasons
        },
        {
          name: 'dispute_type',
          label: this.$t('pages.orders.all.filters.dispute_type.label'),
          type: 'select',
          options: this.disputeTypes
        },
        {
          name: 'dispute_status',
          label: this.$t('pages.orders.all.filters.dispute_status.label'),
          type: 'select',
          options: this.disputeStatuses
        },
        {
          name: 'invoice_id',
          label: this.$t('pages.orders.all.filters.invoice_id.label'),
          type: 'input'
        }
      ]
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', async () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.orders',
        value: config || {}
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.orders.title')
        })
      })
    }
  }
}
</script>
