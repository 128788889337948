<template>
  <div v-if="originCode" class="ml-2 px-2 py-1 rounded origin-button">
    <svgicon
      v-if="originIcon"
      :src="originIcon"
      :style="{
        height: '14px',
        width: '14px'
      }"
    />
    <span class="origin-text underline m-1">
      {{ origins[originCode] || originCode }}
    </span>
  </div>
</template>
<script>
import inStoreIcon from '@/assets/icons/th-icon-instore.svg'
import onlineIcon from '@/assets/icons/th-icon-online.svg'
import safeGet from 'just-safe-get'
import orderSetup from '../constants/order'
export default {
  props: {
    scope: {
      type: Object,
      default: null
    },
    origin: {
      type: String,
      default: null
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const origins = {}
    orderConst.origins.forEach((origin) => {
      origins[origin.value] = origin.label
    })
    return {
      origins
    }
  },
  computed: {
    originIcon() {
      switch (this.originCode) {
        case 'online':
          return onlineIcon
        case 'instore':
          return inStoreIcon
        case 'txn-core':
          return inStoreIcon
        default:
          return null
      }
    },
    originCode() {
      return (
        this.origin ||
        safeGet(this.scope?.row, this.scope?.column?.property, null)
      )
    }
  }
}
</script>
<style scoped>
.origin-button {
  width: fit-content;
  background-color: var(--background-color);
}

.origin-text {
  font-size: var(--button-font-size);
  font-weight: normal;
  letter-spacing: var(--selector-chip-spacing);
  color: var(--font-color);
}
</style>
